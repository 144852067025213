import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import * as React from "react";

import Airdrop from "assets/images/airdrop.svg";
import BetterProduct from "assets/images/better-product.svg";
import CallOption from "assets/images/call-option.svg";
import PageLayout from "components/PageLayout";

import * as S from "./styles";

const ToWowHolders = () => {
  const { t } = useTranslation();

  return (
    <PageLayout>
      <S.MainSection>
        <S.Title>{t("To $WOW holders")}</S.Title>

        <S.Body>
          <S.WhyCard>
            <S.WhyTitle>{t("Why DEFI and not additional feature to WOWswap?")}</S.WhyTitle>
            <S.Why>
              <Trans>
                A couple of months ago we started to think about “the next killing feature” for{" "}
                <a href="https://wowswap.io/swap" target="_blank" rel="noreferrer">
                  WOWswap.io
                </a>{" "}
                and came up with an innovative idea of DEX aggregation and arbitrage built into the
                protocol.
              </Trans>
            </S.Why>
            <S.Why>
              <Trans>
                However, halfway into the development we realized that since “DEX Aggregation and
                Arbitrage” is way more than “Leveraged Trading” protocol, the potential impact and
                adoption of this feature will go beyond WOWswap, and “a feature” should become a
                separate project, and WOWswap would benefit from being the first DAPP to integrate
                with that project.
              </Trans>
            </S.Why>
            <S.Why>
              <Trans>
                So we have created{" "}
                <a href="https://wowmax.exchange/" target="_blank" rel="noreferrer">
                  WOWMAX.exchange
                </a>{" "}
                protocol for DEX aggregation, and built-in arbitrage, and its governance, and
                utility token - Decentralized Finance Token (DEFI).
              </Trans>
            </S.Why>
          </S.WhyCard>

          <S.Benefits>
            <S.BenefitsRow>
              <S.Benefit>
                <S.Row>
                  <BetterProduct />
                  <S.BenefitTitle>{t("Better product")}</S.BenefitTitle>
                </S.Row>
                <S.BenefitText>
                  <Trans>
                    <a href="https://wowmax.exchange/" target="_blank" rel="noreferrer">
                      WOWMAX.exchange
                    </a>{" "}
                    will be the fist DAPP to use WOWMAX protocol, bringing more benefits to
                    WOWswap’s users and stakeholders.
                  </Trans>
                </S.BenefitText>
              </S.Benefit>

              <S.Benefit>
                <S.Row>
                  <Airdrop />
                  <S.BenefitTitle>{t("Airdrop")}</S.BenefitTitle>
                </S.Row>
                <S.BenefitText>
                  <Trans>
                    WOWswap’s DAO members, get ready for the $$$DEFI airdrop! The snapshot of xWOW
                    holders will be taken soon.
                  </Trans>
                </S.BenefitText>
              </S.Benefit>
            </S.BenefitsRow>

            <S.Benefit>
              <S.Row>
                <CallOption />
                <S.BenefitTitle>{t("Call option to WOW token holders")}</S.BenefitTitle>
              </S.Row>
              <S.BenefitText>
                <Trans>
                  If $DEFI market price stays 3 days above $10 (fully diluted marketcap exceeds
                  $1B), $WOW token holders will get the right to buy $DEFI for $1 directly from the
                  Treasury (1 right per 1 WOW token). If realized, this option will add{" "}
                  <b>at least $9</b> to the value of $WOW.
                </Trans>
              </S.BenefitText>
            </S.Benefit>
          </S.Benefits>
        </S.Body>
      </S.MainSection>
    </PageLayout>
  );
};

export default ToWowHolders;
