import styled from "styled-components";

export const MainSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-row-gap: 23px;
`;

export const Title = styled.div`
  font-size: 88px;
  line-height: 93px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 50px;
    line-height: 53px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  grid-column-gap: 6px;
`;

export const Body = styled(Row)`
  align-items: flex-start;
  grid-column-gap: 44px;

  @media (max-width: 768px) {
    flex-direction: column;
    grid-row-gap: 20px;
  }
`;

export const WhyCard = styled.div`
  display: flex;
  flex-direction: column;
  grid-row-gap: 12px;
  max-width: 690px;

  animation-name: blurin;
  animation-duration: 2s;

  @keyframes blurin {
    0% {
      filter: blur(10px);
    }
    100% {
      filter: blur(0px);
    }
  }
`;

export const WhyTitle = styled.h2`
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-size: 30px;
  line-height: 32px;
  color: #ffffff;

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 26px;
  }
`;

export const Why = styled.p`
  margin: 0;
  padding: 0;
  font-size: 15px;
  line-height: 140%;
  color: #90a3de;

  a {
    font-weight: bold;
    color: #be05ff;
  }
`;

export const Benefits = styled.div`
  display: flex;
  flex-direction: column;
  grid-row-gap: 16px;
  max-width: 740px;

  @media (max-width: 768px) {
    grid-row-gap: 20px;
  }
`;

export const BenefitsRow = styled(Row)`
  grid-column-gap: 16px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    grid-row-gap: 20px;
  }
`;

export const Benefit = styled.div`
  padding: 15px 30px 15px 20px;
  display: flex;
  flex-direction: column;
  grid-row-gap: 6px;
  background: linear-gradient(
    297.42deg,
    rgba(190, 5, 255, 0.1) 23.91%,
    rgba(7, 76, 255, 0.1) 91.08%
  );

  @media (max-width: 768px) {
    padding: 15px 20px 15px 15px;
  }

  animation-name: anim;
  animation-duration: 0.7s;

  @keyframes anim {
    0% {
      clip-path: circle(0% at 150% 150%);
    }
    100% {
      clip-path: circle(150%);
    }
  }
`;
export const BenefitTitle = styled.h3`
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-size: 22px;
  line-height: 24px;
  color: #ffffff;
`;

export const BenefitText = styled.p`
  margin: 0;
  padding: 0;
  font-size: 15px;
  line-height: 140%;
  color: #90a3de;

  a {
    font-weight: bold;
    color: #be05ff;
  }

  b {
    font-weight: bold;
    color: #ffffff;
  }
`;
