import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

import Layout from "../components/Layout";
import ToWowHolders from "../containers/ToWowHolders";
import pages from "./pages.json";

const ToWowHoldersPage = () => {
  return (
    <>
      <Helmet>
        <title>{pages.holders.title}</title>
      </Helmet>

      <Layout>
        <ToWowHolders />
      </Layout>
    </>
  );
};

export default ToWowHoldersPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
